//import React from 'react';
import React, { useState } from 'react';
import { func } from 'prop-types';
import { Box, Text, Button, Center, Image, Icon } from '@chakra-ui/react';
import { AiOutlineQuestionCircle as QuestionOutlineIcon } from 'react-icons/ai';

import { THE_BRIDE } from '@/constants';
import {
  ENABLE_ANNOUNCEMENT_MODE,
  ENABLE_GUEST_LIST,
  ENABLE_QR_INVITATION,
} from '@/constants/feature-flags';
import { BUTTON_PROPS, CUSTOM_MODE, STATE_DISPLAY } from '../types';
import ModalInvitation from './ModalInvitation';
import ModalAnnouncement from './ModalAnnouncement';

import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

const MODAL_STATE = {
  invitation: 'invitation',
  announcement: 'announcement',
  idle: '',
};

/**
 * Function to render Welcome Screen
 * @param {props} props
 * @returns {JSX.Element}
 */
function WelcomeScreen({ onSetDisplay, onSetCustomInvitation }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };
  return (
    <Box color="black" fontFamily="Newsreader" textAlign="center" padding="12px 0">
      <Text textAlign="left" color="#1A202C" fontStyle="italic" fontSize="xl">
        Generator Link
      </Text>
      <Text marginTop="16px" color="#1A202C" textAlign="left" textTransform="uppercase">
        The Wedding of
      </Text>
      <Text textAlign="left" color="#1A202C" fontFamily="Newsreader" fontSize="3xl">
        {THE_BRIDE}
      </Text>
      {/* Button Sections */}
      <Box marginTop="24px" fontFamily="Newsreader">
        {!ENABLE_QR_INVITATION && (
          <Button
            {...BUTTON_PROPS}
            fontWeight="normal"
            onClick={() => onSetCustomInvitation(CUSTOM_MODE.invitation)}
          >
            Buat Link Invitation
          </Button>
        )}
        {!ENABLE_QR_INVITATION && (
          <>
            <br />
            <Button
              onClick={() => setModalState(MODAL_STATE.invitation)}
              {...DEFAULT_BUTTON_PROPS}
              fontWeight="normal"
              bgColor="#F0EDE6"
            >
              <Text
                marginBottom="16px"
                color="#1A202C"
                fontWeight="normal"
                marginTop="8px"
                fontStyle="italic"
                fontFamily="Newsreader"
              >
                <Icon as={QuestionOutlineIcon} /> Apa itu Link Invitation?
              </Text>
            </Button>
            <br />
          </>
        )}
        {ENABLE_ANNOUNCEMENT_MODE && (
          <Button
            {...BUTTON_PROPS}
            fontWeight="normal"
            onClick={() => onSetCustomInvitation(CUSTOM_MODE.announcement)}
          >
            Buat Link Announcement
          </Button>
        )}
        <br />
        <Button
          onClick={() => setModalState(MODAL_STATE.announcement)}
          {...DEFAULT_BUTTON_PROPS}
          fontWeight="normal"
          bgColor="#F0EDE6"
        >
          <Text
            marginBottom="16px"
            color="#1A202C"
            fontWeight="normal"
            marginTop="8px"
            fontStyle="italic"
            fontFamily="Newsreader"
          >
            <Icon as={QuestionOutlineIcon} /> Apa itu Link Announcement?
          </Text>
        </Button>
        <br />
        {ENABLE_GUEST_LIST && (
          <Button
            {...BUTTON_PROPS}
            fontWeight="normal"
            onClick={() => onSetDisplay(STATE_DISPLAY.list)}
          >
            Lihat Daftar Undangan
          </Button>
        )}
      </Box>
      {/* Invitato Logo */}
      <Center>
        <Image
          src="https://user-images.githubusercontent.com/10141928/137603877-5d2ddf10-39a7-4586-8ab1-d9cca7fe8898.png"
          maxHeight="30px"
          marginTop="24px"
          filter="invert(1)"
        />
      </Center>

      <ModalInvitation visible={modalState === MODAL_STATE.invitation} onClose={onClose} />
      <ModalAnnouncement visible={modalState === MODAL_STATE.announcement} onClose={onClose} />
    </Box>
  );
}

WelcomeScreen.propTypes = {
  onSetCustomInvitation: func.isRequired,
  onSetDisplay: func.isRequired,
};

export default WelcomeScreen;
