export const BUTTON_PROPS = {
  borderRadius: '24px',
  bgColor: '#E6DBD9',
  fontWeight: 'semibold',
  size: 'md',
  isFullWidth: false,
  fontSize: 'lg',
  border: '1px',
  color: '#1A202C',
  borderColor: 'black',
  fontFamily: 'Newsreader',
};

export const BUTTON_PROPS_OPEN = {
  borderRadius: '34px',
  bgColor: '#E6DBD9',
  fontWeight: 'normal',
  size: 'md',
  isFullWidth: false,
  width: '250px',
  fontSize: 'md',
  border: '1px',
  color: '#1A202C',
  borderColor: 'black',
  fontFamily: 'Newsreader',
};

export const STATE_DISPLAY = {
  welcome: 'welcome',
  custom: 'custom',
  list: 'list',
};

export const CUSTOM_MODE = {
  invitation: 'invitation',
  announcement: 'announcement',
};

export const TYPE_BROADCAST = {
  ID_GENERAL_INVITATION: 'ID_GENERAL_INVITATION',
  EN_GENERAL_INVITATION: 'EN_GENERAL_INVITATION',
  ID_MUSLIM_INVITATION: 'ID_MUSLIM_INVITATION',
  EN_MUSLIM_INVITATION: 'EN_MUSLIM_INVITATION',
  ID_GENERAL_ANNOUNCEMENT: 'ID_GENERAL_ANNOUNCEMENT',
  EN_GENERAL_ANNOUNCEMENT: 'EN_GENERAL_ANNOUNCEMENT',
  ID_MUSLIM_ANNOUNCEMENT: 'ID_MUSLIM_ANNOUNCEMENT',
  EN_MUSLIM_ANNOUNCEMENT: 'EN_MUSLIM_ANNOUNCEMENT',
  CUSTOM_INVITATION: 'CUSTOM_INVITATION',
  CUSTOM_ANNOUNCEMENT: 'CUSTOM_ANNOUNCEMENT',
  ID_REMINDER_RSVP: 'ID_REMINDER_RSVP',
  EN_REMINDER_RSVP: 'EN_REMINDER_RSVP',
  ID_REMINDER_EVENT: 'ID_REMINDER_EVENT',
  EN_REMINDER_EVENT: 'EN_REMINDER_EVENT',
  ID_THANKS: 'ID_THANKS',
  EN_THANKS: 'EN_THANKS',
};

export const LIST_BROADCAST = [
  {
    name: 'Bahasa Indonesia - Umum',
    value: TYPE_BROADCAST.ID_GENERAL_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Indonesia - Muslim',
    value: TYPE_BROADCAST.ID_MUSLIM_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Inggris - Umum',
    value: TYPE_BROADCAST.EN_GENERAL_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Inggris - Muslim',
    value: TYPE_BROADCAST.EN_MUSLIM_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Bahasa Indonesia - Umum',
    value: TYPE_BROADCAST.ID_GENERAL_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Bahasa Indonesia - Muslim',
    value: TYPE_BROADCAST.ID_MUSLIM_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Bahasa Inggris - Umum',
    value: TYPE_BROADCAST.EN_GENERAL_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  {
    name: 'Bahasa Inggris - Muslim',
    value: TYPE_BROADCAST.EN_MUSLIM_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
  // {
  //   name: 'Bahasa Indonesia - Reminder RSVP',
  //   value: TYPE_BROADCAST.ID_REMINDER_RSVP,
  //   type: CUSTOM_MODE.invitation,
  // },
  // {
  //   name: 'Bahasa Inggris - Reminder RSVP',
  //   value: TYPE_BROADCAST.EN_REMINDER_RSVP,
  //   type: CUSTOM_MODE.invitation,
  // },
  // {
  //   name: 'Bahasa Indonesia - Reminder EVENT',
  //   value: TYPE_BROADCAST.ID_REMINDER_EVENT,
  //   type: CUSTOM_MODE.invitation,
  // },
  // {
  //   name: 'Bahasa Inggris - Reminder EVENT',
  //   value: TYPE_BROADCAST.EN_REMINDER_EVENT,
  //   type: CUSTOM_MODE.invitation,
  // },
  // {
  //   name: 'Bahasa Indonesia - Say THANKS',
  //   value: TYPE_BROADCAST.ID_THANKS,
  //   type: CUSTOM_MODE.invitation,
  // },
  // {
  //   name: 'Bahasa Inggris - Say THANKS',
  //   value: TYPE_BROADCAST.EN_THANKS,
  //   type: CUSTOM_MODE.invitation,
  // },
  {
    name: 'Custom Broadcast - Announcement',
    value: TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT,
    type: CUSTOM_MODE.announcement,
  },
  {
    name: 'Custom Broadcast - Invitation',
    value: TYPE_BROADCAST.CUSTOM_INVITATION,
    type: CUSTOM_MODE.invitation,
  },
];

export const FORM_LABEL_OPTIONS = {
  fontSize: 'md',
  color: '#1A202C',
  margin: '8px 0',
  marginTop: '20px',
};

export const INPUT_PROPS = {
  colorScheme: 'blackAlpha',
  border: '1px',
  borderColor: 'black',
  size: 'sm',
  bgColor: '#E6DBD9',
  color: '#1A202C',
  borderRadius: '4px',
  _placeholder: { color: 'black' },
};
export const PHONE_INPUT_PROPS = {
  colorScheme: 'blackAlpha',
  borderTop: '1px',
  borderColor: 'black',
  size: 'sm',
  bgColor: '#E6DBD9',
  color: '#1A202C',
  transform: 'translateX(-1px)',
  borderRadius: '0 4px 4px 0',
  _placeholder: { color: 'black' },
};

export const SELECT_PROPS = {
  colorScheme: 'blackAlpha',
  border: '1px',
  borderColor: 'black',
  size: 'sm',
  bgColor: '#E6DBD9',
  color: '#1A202C',
  borderRadius: '4px',
};

export const PHONE_SELECT_PROPS = {
  colorScheme: 'blackAlpha',
  border: '1px',
  borderColor: 'black',
  size: 'sm',
  bgColor: '#E6DBD9',
  color: '#1A202C',
  borderRadius: '4px 0 0 4px',
};

export const TH_PROPS = {
  fontFamily: 'Newsreader',
  fontSize: 'md',
  padding: '16px',
};

export const OPTION_PROPS = {};

export const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};