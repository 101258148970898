import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function ModalJenisUndangan({ onClose, visible }) {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor="#F0EDE6">
        <DrawerCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor="black"
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"
        >
          Apa itu Jenis Undangan?
        </DrawerHeader>
        <DrawerBody
          align="left"
          fontSize="md"
          fontFamily="Newsreader"
          margin="16px 0"
          color="black"
        >
          <Text>
            <b>- Undangan Personal</b>
            <br /> Link undangan website yang <b>ditujukan kepada tamu secara perorangan</b> atau
            individual
            <br />
            Contoh: Bapak Anton
            <br />
            <br />
            RSVP di link undangan personal{' '}
            <b>hanya bisa memberikan konfirmasi dari orang/undangan yang bersangkutan</b> saja.
            <br />
            <br />
            <b>- Undangan Group </b>
            <br />
            Link undangan website yang <b>ditujukan kepada sebuah kelompok atau group.</b>
            <br />
            Contoh: Alumni SMA N 1
            <br />
            <br />
            RSVP di link undangan group dapat digunakan oleh masing-masing member group yang
            bersangkutan. Setelah RSVP, semua member akan mendapatkan link pribadinya masing-masing.
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalJenisUndangan.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

export default ModalJenisUndangan;
